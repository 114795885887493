<template>
  <div class="container">
    <aside-profile/>
    <div class="main-box">
      <div class="tag-box">
        <img src="../assets/WhatFaithCanDo.png" alt="wordcloud">
      </div>
    </div>
  </div>
</template>

<script>
import AsideProfile from '@/components/AsideProfile'

export default {
  name: 'Tag',
  components: {
    AsideProfile
  }
}
</script>

<style lang="less" scoped>
.container {
  display: flex;
  justify-content: space-between;

  .main-box {
    width: 770px;

    .tag-box {
      background-color: #ffffff;
      border-radius: 8px;
      display: flex;
      padding: 30px;
      align-items: center;
      justify-content: center;

      img {
        width: 600px;
      }
    }

  }
}
</style>
